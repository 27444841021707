@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url("montserrat-latin-ext-100-normal.51381517.woff2") format("woff2"), url("montserrat-latin-ext-100-normal.bd1f88d5.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url("montserrat-latin-ext-200-normal.4bf4f8d2.woff2") format("woff2"), url("montserrat-latin-ext-200-normal.d98087a6.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("montserrat-latin-ext-300-normal.0b11e791.woff2") format("woff2"), url("montserrat-latin-ext-300-normal.af72ee3f.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("montserrat-latin-ext-400-normal.39d79828.woff2") format("woff2"), url("montserrat-latin-ext-400-normal.93a3dac4.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("montserrat-latin-ext-500-normal.c308de9a.woff2") format("woff2"), url("montserrat-latin-ext-500-normal.ce65183a.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("montserrat-latin-ext-600-normal.560b702d.woff2") format("woff2"), url("montserrat-latin-ext-600-normal.f5f60380.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("montserrat-latin-ext-700-normal.f37cf23d.woff2") format("woff2"), url("montserrat-latin-ext-700-normal.10b6d985.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("montserrat-latin-ext-800-normal.5e57fe3d.woff2") format("woff2"), url("montserrat-latin-ext-800-normal.cf0875da.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("montserrat-latin-ext-900-normal.f0fac5de.woff2") format("woff2"), url("montserrat-latin-ext-900-normal.cf587e76.woff") format("woff");
}
/*# sourceMappingURL=index.a215052a.css.map */
